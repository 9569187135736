import React from 'react'
import { Section } from './style';
import Logo from '../../assets/logo.png';

const Loading = ({ full, contained, ...props }) => {
  const Loader = () => <img alt="Esperando" src={Logo} style={{ transition: 'all 300ms ease-in', animationName: 'becomingGreat', animationDuration: '2s', animationIterationCount: 'infinite' }} />

  if (full)
    return (
      <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    )

  if (contained)
    return (
      <Section {...props}>
        <Loader />
      </Section>
    );

  return <Loader />;
}

Loading.defaultProps = {
  contained: true
}

export default Loading;