import React from "react";
import { Typography } from "@material-ui/core";
import FlexBox from "components/FlexBox";
import NotFoundSvg from '../../assets/not-found.svg'

const NotFound = () => {
  return (
    <FlexBox justify="center" alignItems="center" style={{ height: '100%' }}>
      <Typography color="primary" variant="h3">
        Não encontramos a página :(
      </Typography>

      <img src={NotFoundSvg} alt="Página não encontrada" style={{ width: '80%', height: 400, marginTop: '2em' }} />
    </FlexBox>
  )
};

export default NotFound;
