import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgb(128 128 128 / 30%);
	border-radius: 8px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 8px;
  height: 8px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgb(128 128 128 / 30%);
	background-color: #8266F9;
}

@keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}   
}

@keyframes becomingGreat {
  0% {
    height: 130px;
    opacity: 0.5;
  }

  25% {
    height: 160px;
    opacity: 0.6;
  }

  50% {
    height: 190px;
    opacity: 0.7;
  }

  75% {
    height: 220px;
    opacity: 0.85;
  }

  100% {
    height: 250px;
    opacity: 1;
  } 
}`;
