import React, { Suspense, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";
import Loading from "../components/Loading";
import NotFound from "pages/not-found";

const Login = lazy(() => import("../pages/login/index.js"));
const AdminLayout = lazy(() => import("./admin-routes.js"));

const Routes = () => (
  <Router history={history}>
    <Suspense fallback={<Loading full />}>
      <Switch>
        <Route path={["/admin"]} component={AdminLayout} />
        <Route path={["/login", "", "/"]} component={Login} exact />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </Router>
);

export default Routes;
