import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  palette: {
    primary: { light: "#8266F9", main: "#8266F9", dark: "#8266F9" },
    secondary: { light: "#3BC55A", main: "#3BC55A", dark: "#3BC55A" },
    text: {
      primary: "#828282",
      secondary: "#9c9c9c",
    },
  },
  typography: {
    h1: {
      color: "#5a5a5ad4",
    },
    h2: {
      color: "#5a5a5ad4",
    },
    h3: {
      color: "#5a5a5ad4",
    },
    h4: {
      color: "#5a5a5ad4",
    },
    h5: {
      color: "#5a5a5ad4",
    },
    h6: {
      color: "#5a5a5ad4",
    },
    fontFamily: `'Public Sans', sans-serif`,
  },
  overrides: {
    MuiAccordionSummary: {
      content: {
        margin: "12px 0 !important",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgb(0 0 0 / 18%)",
      },
    },
    MuiButton: {
      root: {
        fontFamily: `'Public Sans', sans-serif`,
      },
      text: {
        color: "#16d173",
      },
      textPrimary: {
        color: "#FFF",
      },
      containedPrimary: {
        color: "#FFF",
      },
      containedSecondary: {
        color: "#FFF",
      },
    },
    MuiStepIcon: {
      root: {
        color: "rgba(255, 255, 255, 0.38)",
        border: "1px solid #16d173",
        borderRadius: 15,
        "& text": {
          fill: "#16d173",
        },
        "&$active text": {
          fill: "#fff",
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        "& p": {
          color: "#FFF",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          "-1px -1px 13px 1px rgba(0,0,0,0.12), 2px 2px 10px 0px rgba(0,0,0,0.12)",
      },
    },
    MuiChip: {
      colorPrimary: {
        color: "#FFF",
      },
      colorSecondary: {
        color: "#FFF",
      },
    },
  },
});

export const DefaultFormItemStyle = {
  paddingBottom: 0,
};
