import React, { useEffect } from "react";
import GlobalStyle from "./styles/global";
import { ToastProvider } from "react-toast-notifications";
import ToastContainer from "./components/Toast/ToastContainer";
import ToastTop from "./components/Toast/ToastTop";
import Routes from "./layouts/main-routes";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import theme from "./styles/theme";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import qz from "qz-tray";
import { KEYUTIL, KJUR, stob64, hextorstr } from "jsrsasign";

const App = () => {
  useEffect(() => {
    async function makeJob() {
      var certicateKey =
        "-----BEGIN CERTIFICATE-----\n" +
        "MIID4TCCAsmgAwIBAgIUW5SWkZvx9zrjuwql3tpfpGC/IZQwDQYJKoZIhvcNAQEL\n" +
        "BQAwfzELMAkGA1UEBhMCQlIxEjAQBgNVBAgMCVBhcmFuw4PCoTEUMBIGA1UEBwwL\n" +
        "UGF0byBCcmFuY28xDjAMBgNVBAoMBU1hZ3VzMRQwEgYDVQQDDAsqLm1hZ3VzLmZ1\n" +
        "bjEgMB4GCSqGSIb3DQEJARYRY29udGF0b0BtYWd1cy5mdW4wIBcNMjMwMTA0MjI1\n" +
        "MzEyWhgPMjA1NDA2MjkyMjUzMTJaMH8xCzAJBgNVBAYTAkJSMRIwEAYDVQQIDAlQ\n" +
        "YXJhbsODwqExFDASBgNVBAcMC1BhdG8gQnJhbmNvMQ4wDAYDVQQKDAVNYWd1czEU\n" +
        "MBIGA1UEAwwLKi5tYWd1cy5mdW4xIDAeBgkqhkiG9w0BCQEWEWNvbnRhdG9AbWFn\n" +
        "dXMuZnVuMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6jCwB31tflrt\n" +
        "6hI9zrV25RD0e6tTjT4BEqfxpgd3o4HW/iH6PteU8lFcOOaS0T3ow0l24Y1PZnds\n" +
        "UhHubMjbJu96QiOTGzbA90GVhlur1K237paXQzb/cbl1wQ2BTxWdj9u92yU6l/Ot\n" +
        "jO5/l4M418m7C0bLlThgzdzEAG119v1UGVgsxMZRkHvdVu1X3UKrkWhzhqSXxeTC\n" +
        "FnvyjzurEUGd21yrGbOlVl9e7n4SkxurPIdFSyJE+uYRCPe5xTXJmcITUvrqGfqZ\n" +
        "nV5WcOf73/wTRdhiL/H2tRe7Ec7qudu2ACsihS9JIL3V9PCr6UGcN/q0t2qpSOsv\n" +
        "9aa2YIOGvwIDAQABo1MwUTAdBgNVHQ4EFgQUeiMWJHie4sHzMu44tGY4jkKlVVYw\n" +
        "HwYDVR0jBBgwFoAUeiMWJHie4sHzMu44tGY4jkKlVVYwDwYDVR0TAQH/BAUwAwEB\n" +
        "/zANBgkqhkiG9w0BAQsFAAOCAQEAWCSBAeRUeUhk30jiU9kwTcwuWgQWzdoYhOEH\n" +
        "R6sD8dQrkidCu1i1t9m3zqeDGvYOg1BZvKKdyjrN1DK8LV9iuUjBHdExCePUjagE\n" +
        "mauLZA3fP9aOXsFu4ziP3WVkCxzvFZwzkjHVk0Z9Vk6PU1BiCe6MOpBriQpNkK/w\n" +
        "1kZrtrHlO2gDltVUdBgjXXifnmt62EEdEn4tPyRPmll4vD92vOUeXMSFLn5Zt7xS\n" +
        "TC5zSXQGOVh6P4HCNbocHxiQQGQjc6SSHq7CsUYRulrtWxkwMqXdEQsBMHIQYiXe\n" +
        "SBxboo2legySXIosuvoEG5JuRdb+nX7R0yUKjkP3KGXHi6cjyQ==\n" +
        "-----END CERTIFICATE-----";

      var privateKey =
        "-----BEGIN RSA PRIVATE KEY-----\n" +
        "MIIEowIBAAKCAQEA6jCwB31tflrt6hI9zrV25RD0e6tTjT4BEqfxpgd3o4HW/iH6\n" +
        "PteU8lFcOOaS0T3ow0l24Y1PZndsUhHubMjbJu96QiOTGzbA90GVhlur1K237paX\n" +
        "Qzb/cbl1wQ2BTxWdj9u92yU6l/OtjO5/l4M418m7C0bLlThgzdzEAG119v1UGVgs\n" +
        "xMZRkHvdVu1X3UKrkWhzhqSXxeTCFnvyjzurEUGd21yrGbOlVl9e7n4SkxurPIdF\n" +
        "SyJE+uYRCPe5xTXJmcITUvrqGfqZnV5WcOf73/wTRdhiL/H2tRe7Ec7qudu2ACsi\n" +
        "hS9JIL3V9PCr6UGcN/q0t2qpSOsv9aa2YIOGvwIDAQABAoIBAQCoL5Mbre5mojLe\n" +
        "uX795sCePZD3B9GXTAJFPauDyzUWjfayyAAKBZUIeXm7u4uRDd8N8MKZtXxiYD3A\n" +
        "qvQJ2C3bRn0M5rONBcOLTuRte4Rp1uCTTj8PnH3TVJfGr0ZAX4uqdutgaNotqmjn\n" +
        "H5kuXKUEA/J6ZLtsTPLopTV2ASaI24I4HfMNNjH7aI33B5j3Koofb0ukKXqIqQl+\n" +
        "PH20JekKbqDt3W2S995/SQhLlkf3IWu0dthJMk1IYHX4A2NXCz4rjNzV9/D3Hkrn\n" +
        "vE+2R5g5BXNtyOtXhQ51j6vniwv91tSJJIJFELLonUhR9/eqDqCepOta6phdDLht\n" +
        "jI6yQTmRAoGBAPnzfILIpog8EV39MQfer/c7kZBbcySf0oYon5IUSwFWZ/Q6yVEX\n" +
        "FGqbtIzm3aNIVNMPpQMk8MRwcme8x5YXeVM7Q3hhmi0fsKgkvIKDttZtPDjDa0VB\n" +
        "MtWxNk8s/olasKpbUpIKIddQ90FU6t2zVMWBk6BxGAzJn0nfWB4Mtbs1AoGBAO/b\n" +
        "jt4QZQVFhUiJbbo01kB983V8QKHWeguW+cJ1Pe+vxyPocmSuA2Zr0tgYtqPOF7AO\n" +
        "f2RW757KMHxVuJFzHBDFoGFhjRWIUgwn18xc5QGL3on1owfkOZ6D5WquAPRmCe4F\n" +
        "uQgA2dDsuNB7Vt92q+QvEVYLyV/D8ZyD9JCF7oSjAn9etON4qo5vEFOESWtKkBYc\n" +
        "l3WQPRZjyir6qmnL7+R7nUHGSH//Ob+CpXzD5rYUKFP7pPHhPx37UKAIrn7xX/OY\n" +
        "ldv6wQCqGFfXXhQPX9jnZnxrBaCfnRSl43+wgFvxr2tBIVkn/K7V5tfI6WQUnN6B\n" +
        "95b4M+Ho9nxkgTviJu61AoGBALygartCI1/A628+/HbKZI+1PUfwXhenxrTklygQ\n" +
        "8ZNUJgScIZIBsxTRyK8+yCsLwV8oF5D+OVUj5ntxIkPoRXL/KABNKhl76iI7Fx9V\n" +
        "Epdjuq+95+cl2RZK3ZlGm+OXd0mjYH7lxGXkM/dod8om1kkNptGHVWclfhW7RBJ/\n" +
        "8TKHAoGBAM8fhIPivO2ruYa0bImRkDX5bA05JE+UUsmOuSQUghv7R/q281n1/8is\n" +
        "AhBm2PZs1WjE0RNbn28tDRk4PRYeeGU6uafbvuxwfIWP4KLQjYCiPHEoLl3D4Sa2\n" +
        "XEaZ6o54w3678RqmR6SoM3TbntWxPi9E7C2ej3889LYPMQQ1lyNs\n" +
        "-----END RSA PRIVATE KEY-----";

      qz.security.setCertificatePromise(function (resolve) {
        resolve(certicateKey);
      });

      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          try {
            var pk = KEYUTIL.getKey(privateKey);
            var sig = new KJUR.crypto.Signature({ alg: "SHA1withRSA" });
            sig.init(pk);
            sig.updateString(toSign);
            var hex = sig.sign();
            resolve(stob64(hextorstr(hex)));
          } catch (err) {
            reject(err);
          }
        };
      });
    }

    makeJob();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ToastProvider
        placement={"bottom-right"}
        components={{ ToastContainer: ToastContainer, Toast: ToastTop }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <CssBaseline />
          <Routes />
          <GlobalStyle />
        </MuiPickersUtilsProvider>
      </ToastProvider>
    </MuiThemeProvider>
  );
};
export default App;
